import React, { useMemo, Fragment, useState } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  Table,
  Modal,
  ModalBody,
  Form,
  Label,
} from "reactstrap"
import { useQuery, useMutation } from "react-query"
import { useTable } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GENERAL } from "api/general"
//i18n
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import dayjs from "dayjs"
import { AUTH } from "api/auth"
import { API_RECORD_LIST_SIZE } from "helpers/global_variables.helper"
import useDebounce from "helpers/hooks/useDebounce"
import PaginationComponent from "components/Common/pagination"
import ActivityLoader from "components/activity-loader"

const UserList = props => {
  const [currentIndex, setCurrentIndex] = useState(1)

  const [nin, setNin] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [photo, setPhoto] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [states, setStates] = useState([])
  const [lgas, setLgas] = useState([])

  const [selectedState, setSelectedState] = useState("")
  const [selectedLga, setSelectedLga] = useState("")
  const [selectedRole, setSelectedRole] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [show, setShow] = useState(false)
  const [isNinVerified, setIsNinVerified] = useState(false)
  const [isEmailErr, setIsEmailErr] = useState(true)
  const [isPasswordErr, setIsPasswordErr] = useState(false)
  const [isStateErr, setIsStateErr] = useState(true)
  const [isLgaErr, setIsLgaErr] = useState(true)
  const [isRoleErr, setIsRoleErr] = useState(true)
  const [isRegionErr, setIsRegionErr] = useState(true)
  const [searchFilter, setSearchFilter] = React.useState("")

  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  //meta title
  document.title = "ManageUsers - eRecruitement"

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      "get Admin List",
      {
        searchQuery,
        currentIndex,
      },
    ],
    queryFn: () => {
      return GENERAL.getUsersByRole({
        page: currentIndex,
        role: "",
        search: searchQuery,
        take: API_RECORD_LIST_SIZE,
      })
    },
  })

  const getStatesAndLgas = useQuery({
    queryKey: ["getStatesAndLgas"],
    queryFn: () => {
      return GENERAL.getStatesAndLgas()
    },
    onSuccess: async res => {
      setStates(res)
    },
  })

  const handleSelectState = evt => {
    const state = evt.target.value
    setSelectedState(evt.target.value)
    if (selectedRole !== "state_coordinator") {
      Swal.fire({
        title: "Fetching LGAs...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      Swal.close()
      const lgas = states.find(
        item =>
          String(item?.state).toLowerCase() === String(state).toLowerCase()
      ).lgas
      setLgas(lgas)
    }
  }

  const handleSelectLga = evt => {
    setSelectedLga(evt.target.value)
  }

  const handleSubmit = () => {
    // closeModal()

    if (email) {
      setIsEmailErr(false)
    } else {
      return Swal.fire("Process Failed!", "Email is required.", "error")
    }
    if (selectedRole != null) {
      setIsRoleErr(false)
    } else {
      return Swal.fire("Process Failed!", "Role is required.", "error")
    }
    if (
      (selectedRegion != null && selectedRole == "regional_coordinator") ||
      (selectedRegion == null && selectedRole !== "regional_coordinator")
    ) {
      setIsRegionErr(false)
    } else {
      return Swal.fire("Process Failed!", "Region is required.", "error")
    }

    // state check
    if (selectedRole != null) {
      if (
        (selectedRole === "supervisor" ||
          selectedRole === "state_coordinator" ||
          selectedRole === "lga_coordinator") &&
        selectedState !== ""
      ) {
        setIsStateErr(false)
      } else if (
        selectedRole === "super_admin" ||
        selectedRole === "admin" ||
        selectedRole === "regional_coordinator"
      ) {
        setIsStateErr(false)
      } else {
        Swal.fire("Process Failed!", "State is required.", "error")
      }

      // lga check
      if (selectedState !== "") {
        if (
          (selectedRole === "supervisor" ||
            selectedRole === "state_coordinator" ||
            selectedRole === "lga_coordinator") &&
          selectedLga !== ""
        ) {
          setIsLgaErr(false)
        } else if (
          selectedRole === "super_admin" ||
          selectedRole === "admin" ||
          selectedRole === "regional_coordinator" ||
          selectedRole === "state_coordinator"
        ) {
          setIsLgaErr(false)
        } else {
          Swal.fire("Process Failed!", "LGA is required.", "error")
        }
      }
    }

    // email check
    if (selectedRole != null) {
      // setEmail("")
      if (email != null && email !== "") {
        if (selectedRole === "supervisor") {
          if (!email.toLocaleLowerCase().includes("@nimc.gov.ng")) {
            Swal.fire("Process Failed!", "Provide a valid NIMC email", "error")
            return
          }
        } else if (
          selectedRole === "super_admin" ||
          selectedRole === "admin" ||
          selectedRole === "regional_coordinator" ||
          selectedRole === "state_coordinator" ||
          selectedRole === "lga_coordinator"
        ) {
          if (
            !email.toLocaleLowerCase().includes("@") ||
            email.toLocaleLowerCase().includes("@nimc.gov.ng")
          ) {
            Swal.fire("Process Failed!", "Email is not valid", "error")
            return
          }
        }
      }
    } else {
      setIsRoleErr(false)
    }

    if (
      (selectedRole != null &&
        // selectedRole === "super_admin" &&
        selectedRole === "super_admin" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr) ||
      (selectedRole != null &&
        // selectedRole === "super_admin" &&
        selectedRole === "admin" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr) ||
      (selectedRole != null &&
        selectedRole === "state_coordinator" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr &&
        !isStateErr) ||
      (selectedRole != null &&
        selectedRole === "regional_coordinator" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr &&
        !isRegionErr) ||
      (selectedRole != null &&
        selectedRole === "lga_coordinator" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr &&
        !isStateErr &&
        !isLgaErr) ||
      (selectedRole != null &&
        selectedRole === "supervisor" &&
        !isEmailErr &&
        !isPasswordErr &&
        !isRoleErr &&
        !isStateErr &&
        !isLgaErr)
    ) {
      closeModal()
      const createUserDto = {
        name: `${firstname} ${lastname}`,
        photo: photo,
        email: email,
        // password: password,
        stateOfOrigin: selectedState,
        lgaOfOrigin: selectedLga,
        // confirmPassword: password,
        // phone: phone,
        phone: "09234345645",
        role: selectedRole, // or supervisor
        nin: nin,
        zone: selectedRole === "regional_coordinator" ? selectedRegion : "0",
      }

      Swal.fire({
        title: "Creating user...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      createUser.mutate(createUserDto)
    } else {
    }
  }

  const createUser = useMutation({
    mutationFn: payload => {
      return AUTH.createUser(payload)
    },
    onSuccess: async response => {
      Swal.close()
      Swal.fire("Process Successful", response.data.message, "success")
    },

    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })

  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return data?.records?.map((_, index) => index + offset + 1)
  }, [data, currentIndex, API_RECORD_LIST_SIZE])

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "index",
        Cell: cell => {
           return <p>{getSns[cell.row.index]}</p>
        },
      },
      {
        Header: "Role",
        accessor: "role",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "stateOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lgaOfOrigin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
    ],
    [currentIndex,data,API_RECORD_LIST_SIZE]
  )

  const FormatIndex = cell => {
    const index = +cell?.row?.id + 1
    return index
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: data?.records ?? [],
    initialState: {
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatText = cell => {
    return cell.value ? cell.value : ""
  }

  const showModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setIsNinVerified(false)
    setEmail("")
    setShow(false)
  }

  const handleVerifyNinFn = () => {
    Swal.fire({
      title: "Verifying NIN...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const verifyNinDto = {
      nin: nin,
    }
    verifyNinFn.mutate(verifyNinDto)
  }

  const verifyNinFn = useMutation({
    mutationFn: payload => {
      return GENERAL.authVerifyFn(payload)
    },
    onSuccess: async res => {
      Swal.close()
      if (res?.status == 201) {
        setFirstname(res?.data?.firstname)
        setLastname(res?.data?.lastname)
        setPhone(res?.data?.phone)
        setPhoto(res?.data?.photo)
        setIsNinVerified(true)
      }
    },

    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5>Create User</h5>

                <div onClick={closeModal}>
                  <i
                    className="bx bx-x"
                    style={{ fontSize: 24, cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </Col>
            <Form
            // onSubmit={e => {
            //   e.preventDefault()
            //   handleSubmit()
            //   return false
            // }}
            >
              {!isNinVerified && (
                <Row>
                  <Col md={9}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        NIN <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="number"
                          placeholder="Enter NIN"
                          maxLength={11}
                          name="firstname"
                          onChange={evt => setNin(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="text-center mt-3">
                      {!verifyNinFn.isLoading && (
                        <button
                          type="submit"
                          className="btn btn-primary w-80 ml-5"
                          onClick={handleVerifyNinFn}
                        >
                          VERIFY NIN
                        </button>
                      )}
                      {verifyNinFn.isLoading && (
                        <button
                          type="submit"
                          className="btn btn-primary w-80 ml-5"
                        >
                          VERIFYING NIN
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              {isNinVerified && (
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          placeholder="Enter First Name"
                          name="firstname"
                          value={firstname}
                          disabled
                          onChange={evt => setFirstname(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          name="lastname"
                          value={lastname}
                          disabled
                          placeholder="Enter Last Name"
                          onChange={evt => setLastname(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Phone Number <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          name="phone"
                          value={phone}
                          disabled
                          placeholder="Enter Phone Number"
                          onChange={evt => setPhone(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          name="email"
                          value={email}
                          required={true}
                          placeholder="Enter Email"
                          // onChange={evt => handleSetEmail(evt.target.value)}
                          onChange={evt => setEmail(evt.target.value)}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-password-Input">
                        Role <span className="text-danger">*</span>
                      </Label>
                      <div className="col-md-12">
                        <select
                          onChange={evt => setSelectedRole(evt.target.value)}
                          className="form-control"
                        >
                          <option>Select</option>
                          <option value="super_admin">SUPER ADMIN</option>
                          <option value="admin">ADMIN</option>
                          <option value="supervisor">NIMC STAFF</option>
                          <option value="regional_coordinator">
                            REGIONAL COORDINATOR
                          </option>
                          <option value="state_coordinator">
                            STATE COORDINATOR
                          </option>
                          <option value="lga_coordinator">
                            LGA COORDINATOR
                          </option>
                        </select>
                      </div>
                    </div>
                  </Col>

                  {selectedRole && selectedRole === "regional_coordinator" && (
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password-Input">
                          Region <span className="text-danger">*</span>
                        </Label>
                        <div className="col-md-12">
                          <select
                            onChange={evt =>
                              setSelectedRegion(evt.target.value)
                            }
                            className="form-control"
                          >
                            <option>Select</option>
                            <option value="1">NORTH WEST</option>
                            <option value="2">NORTH EAST</option>
                            <option value="3">NORTH CENTRAL</option>
                            <option value="4">SOUTH EAST</option>
                            <option value="5">SOUTH SOUTH</option>
                            <option value="6">SOUTH WEST</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                  )}

                  {((selectedRole && selectedRole === "supervisor") ||
                    (selectedRole && selectedRole === "state_coordinator") ||
                    (selectedRole && selectedRole === "lga_coordinator") ||
                    (selectedRole && selectedRole === "admin")) && (
                    <>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            State <span className="text-danger">*</span>
                          </Label>
                          <div className="col-md-12">
                            <select
                              onChange={handleSelectState}
                              className="form-control"
                            >
                              <option>Select</option>
                              {states?.map(state => (
                                <option key={state.state} value={state.state}>
                                  {state.state}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                  {((selectedRole && selectedRole === "admin") ||
                    (selectedRole && selectedRole === "supervisor") ||
                    (selectedRole && selectedRole === "lga_coordinator")) && (
                    <>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            Lga <span className="text-danger">*</span>
                          </Label>
                          <div className="col-md-12">
                            <select
                              onChange={handleSelectLga}
                              className="form-control"
                            >
                              <option>Select</option>
                              {lgas?.map(lga => (
                                <option key={lga.lga_name} value={lga.lga_name}>
                                  {lga.lga_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              )}
            </Form>
          </Row>
          {isNinVerified && (
            <Row>
              <div className="text-center">
                <button
                  style={{ marginRight: 10 }}
                  className="btn btn-danger w-80 mr-2"
                  onClick={() => closeModal()}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-80 ml-5"
                  // onClick={handleSubmit}
                  onClick={e => {
                    e.preventDefault()
                    handleSubmit()
                    return false
                  }}
                >
                  SUBMIT
                </button>
              </div>
            </Row>
          )}
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Account Management")}
              breadcrumbItem={props.t("Manage Users")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">Account Management</div>
              <Fragment>
                <Row className="mb-2">
                  <Col md={10}>
                    <React.Fragment>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={({ target }) =>
                                setSearchFilter(target.value)
                              }
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`search...`}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          ></div>
                        </div>
                      </div>
                    </React.Fragment>
                  </Col>

                  <Col sm="2">
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="btn mb-2 me-2"
                        onClick={() => showModal()}
                      >
                        Create User
                      </Button>
                    </div>
                  </Col>
                </Row>

                <div className="table-responsivreact-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {isLoading || isFetching ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : rows.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (data?.total || 0) / API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(UserList)
