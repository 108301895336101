import React, { useMemo, Fragment, useState, useEffect } from "react"
import { Container, Col, Row, Button, Card, CardBody, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useParams } from "react-router"
import { GENERAL } from "../../../api/general"
import Swal from "sweetalert2"
import useDebounce from "helpers/hooks/useDebounce"
import {
  API_LIST_ORDER,
  API_RECORD_LIST_SIZE,
} from "helpers/global_variables.helper"
import ActivityLoader from "components/activity-loader"
import dayjs from "dayjs"

const ViewBeneficiaryPaymensByState = props => {
  const params = useParams()
  const [page, setPage] = useState(1)
  const [currentIndex, setCurrentIndex] = useState(1)
  const [data, setRecords] = useState([
    // {
    //   id: 1,
    //   fullname: "John Smith",
    //   nin: "12345678900",
    //   phone: "12345678900",
    //   status: "active",
    //   state: "Abuja",
    //   lga: "FCT",
    //   bank: "First Bank",
    //   bankNumber: "12345678900",
    //   created_at: "2015-09-12",
    // },
  ])

  const [searchFilter, setSearchFilter] = React.useState("")
  const onChange = value => {
    setSearchFilter(value)
  }
  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const { data: getVerificationsByState, isLoading } = useQuery({
    queryKey: [
      "get_verifications_by_state",
      {
        verificationStatus: false,
        page: currentIndex,
        searchQuery,
        state: params.state,
      },
    ],
    queryFn: () => {
      return GENERAL.getVerifications({
        order: API_LIST_ORDER.desc,
        verificationStatus: false,
        search: searchQuery,
        page: currentIndex,
        take: API_RECORD_LIST_SIZE,
        state: params.state,
      })
    },
    onSuccess: async res => {
      setRecords(res.records)
    },
  })
  //meta title
  document.title = "Agents Registration - eRecruitement"

  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return getVerificationsByState?.records?.map((_, index) => index + offset + 1)
  }, [getVerificationsByState, currentIndex, API_RECORD_LIST_SIZE])

 const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }


  const NINFormat = cell => {
    const NIN = cell.cell.row.original.beneficiary
      ? cell.cell.row.original.beneficiary.nin
      : ""
    const TrackingId = cell.cell.row.original.beneficiary
      ? cell.cell.row.original?.beneficiary.beneficiaryNin?.trackingId
      : ""

    if (NIN) {
      return <p>{NIN}</p>
    } else {
      return <p>{TrackingId}</p>
    }
  }
   const FormatVerificationStatus = cell => {
     return cell.value ? "Verified" : "Not Verified"
   }

   const columns = useMemo(
     () => [
       {
         Header: "S/N",
         Cell: cell => {
           return <p>{getSns[cell.row.index]}</p>
         },
       },
       {
         Header: "Full Name",
         Cell: cellProps => {
           const firstName = cellProps.row.original.beneficiary.firstName
           const middleName = cellProps.row.original.beneficiary.middleName
           const surname = cellProps.row.original.beneficiary.surname
           return `${firstName + " " ?? ""}${middleName + " " ?? ""}${
             surname + " " ?? ""
           } `
         },
       },
       {
         Header: "Beneficiary ID",
         accessor: "beneficiary.beneficiaryId",
       },
       {
         Header: "NIN/Tracking ID",
         Cell: cellProps => {
           return <NINFormat {...cellProps} />
         },
       },
       {
         Header: "Phone Number",
         accessor: "beneficiary.phoneNumber",
       },

       // {
       //   Header: "LGA of Residence",
       //   accessor: "beneficiary.lgaOfOrigin",
       // },
       // {
       //   Header: "Address",
       //   accessor: "beneficiary.address",
       // },
       {
         Header: "Verification Status",
         accessor: "verificationStatus",
         disableFilters: true,
         filterable: false,
         Cell: cellProps => {
           return <FormatVerificationStatus {...cellProps} />
         },
       },
       {
         Header: "State",
         accessor: "beneficiary.stateOfOrigin",
       },
       {
         Header: "Agent",
         accessor: "agent",
       },
       {
         Header: "Verification Date",
         accessor: "verificationDate",
         disableFilters: true,
         filterable: false,
         Cell: cellProps => {
           return <DateFormat {...cellProps} />
         },
       },
     ],
     [data, currentIndex]
   )

  const makePaymentFn = evt => {
    console.log("this is the beneficiary record :: ", evt.cell.row.values)
    const beneficiary = evt.cell.row.values
    Swal.fire({
      title: "Processing Payment...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    const paymentDto = {
      beneficiaryId: beneficiary.id,
      month: beneficiary.month,
      year: beneficiary.year,
    }
    makePayment.mutate(paymentDto)
  }

  const makePayment = useMutation({
    mutationFn: payload => {
      return GENERAL.paySingleBeneficiary(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("paySingleBeneficiary response ::", res)
      if (res.status === 201) {
        return Swal.fire("Process Successful", res.data.message, "success")
      }
      if (res.status === 400) {
        return Swal.fire("Process Failed!", res.data.message, "error")
      }
    },
  })

  const payAllFn = evt => {
    console.log("this is the beneficiary record :: ", data[0])
    const beneficiary = data[0]
    Swal.fire({
      title: "Processing Payment...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    const paymentDto = {
      state: beneficiary.state_of_origin,
      month: beneficiary.month,
      year: beneficiary.year,
    }
    console.log("paymentDto :: ", paymentDto)
    payAll.mutate(paymentDto)
  }
  const payAll = useMutation({
    mutationFn: payload => {
      return GENERAL.payAllState(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("payAllState response ::", res)
      if (res.status === 201) {
        Swal.fire("Process Successful", res.data.message, "success")
      }
    },
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: getVerificationsByState?.records ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: API_RECORD_LIST_SIZE,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Payment")}
              childLink={props.t("Beneficiary")}
              thirdLink={props.t("State")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">
                All Beneficiariesss - {getVerificationsByState?.total}
              </div>
              <Fragment>
                <Row className="mb-2">
                  {/* {isGlobalFilter && <GlobalFilter />} */}
                  <React.Fragment>
                    <Row>
                      <Col md={6}>
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div
                            className="position-relative"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              htmlFor="search-bar-0"
                              className="search-label"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                onChange={e => {
                                  onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`search`}
                                value={searchFilter || ""}
                              />
                            </label>
                            <i
                              className="bx bx-search-alt search-icon"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            className="btn mb-2 me-2"
                            onClick={payAllFn}
                          >
                            Pay All
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {isLoading ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : rows.length > 0 ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewBeneficiaryPaymensByState)
