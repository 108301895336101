import { ROLES } from "helpers/global_variables.helper"
import React, { lazy, useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"

const SuperAdminView = lazy(() => import("./SuperAdmin.view"))
const StateCoordinatorView = lazy(() => import("./StateCoordinator.view"))

const BeneficiaryByLga = props => {
  const [userRole, setUserRole] = useState()
  useEffect(() => {
    setUserRole(JSON.parse(sessionStorage.getItem("userRole")))
  }, [])

  return (
    <>
      {userRole == ROLES.superadmin && <SuperAdminView />}
      {userRole == ROLES.state_coordinator && <StateCoordinatorView />}
    </>
  )
}

export default withTranslation()(BeneficiaryByLga)
