import React, { Fragment, useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { GENERAL } from "../../../api/general"
import { useQuery } from "react-query"
import { data } from "common/data/states"
import errorImg from "assets/images/error-img.png"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Swal from "sweetalert2"

const SuperAdminAgentPayment = props => {
  const [userRole, setUserRole] = useState(
    JSON.parse(sessionStorage.getItem("userRole"))
  )
  const [isErr, setIsErr] = useState(false)
  const [records, setRecords] = useState([
    {
      id: 31,
      name: "ABIA",
      threshold: 595,
      approvedUserCount: 0,
    },
    {
      id: 7,
      name: "ADAMAWA",
      threshold: 735,
      approvedUserCount: 0,
    },
    {
      id: 30,
      name: "AKWA IBOM",
      threshold: 1085,
      approvedUserCount: 0,
    },
    {
      id: 26,
      name: "ANAMBRA",
      threshold: 735,
      approvedUserCount: 0,
    },
    {
      id: 9,
      name: "BAUCHI",
      threshold: 700,
      approvedUserCount: 0,
    },
    {
      id: 34,
      name: "BAYELSA",
      threshold: 280,
      approvedUserCount: 0,
    },
    {
      id: 18,
      name: "BENUE",
      threshold: 805,
      approvedUserCount: 0,
    },
    {
      id: 6,
      name: "BORNO",
      threshold: 2084,
      approvedUserCount: 0,
    },
    {
      id: 29,
      name: "CROSS RIVER",
      threshold: 630,
      approvedUserCount: 0,
    },
    {
      id: 35,
      name: "DELTA",
      threshold: 875,
      approvedUserCount: 0,
    },
    {
      id: 28,
      name: "EBONYI",
      threshold: 1468,
      approvedUserCount: 0,
    },
    {
      id: 25,
      name: "EDO",
      threshold: 630,
      approvedUserCount: 0,
    },
    {
      id: 23,
      name: "EKITI",
      threshold: 560,
      approvedUserCount: 0,
    },
    {
      id: 27,
      name: "ENUGU",
      threshold: 595,
      approvedUserCount: 0,
    },
    {
      id: 14,
      name: "FCT ABUJA",
      threshold: 210,
      approvedUserCount: 1,
    },
    {
      id: 8,
      name: "GOMBE",
      threshold: 385,
      approvedUserCount: 0,
    },
    {
      id: 32,
      name: "IMO",
      threshold: 945,
      approvedUserCount: 0,
    },
    {
      id: 4,
      name: "JIGAWA",
      threshold: 945,
      approvedUserCount: 0,
    },
    {
      id: 11,
      name: "KADUNA",
      threshold: 805,
      approvedUserCount: 0,
    },
    {
      id: 10,
      name: "KANO",
      threshold: 1540,
      approvedUserCount: 0,
    },
    {
      id: 3,
      name: "KATSINA",
      threshold: 1190,
      approvedUserCount: 0,
    },
    {
      id: 12,
      name: "KEBBI",
      threshold: 735,
      approvedUserCount: 0,
    },
    {
      id: 19,
      name: "KOGI",
      threshold: 735,
      approvedUserCount: 0,
    },
    {
      id: 20,
      name: "KWARA",
      threshold: 560,
      approvedUserCount: 0,
    },
    {
      id: 36,
      name: "LAGOS",
      threshold: 700,
      approvedUserCount: 0,
    },
    {
      id: 15,
      name: "NASARAWA",
      threshold: 1634,
      approvedUserCount: 0,
    },
    {
      id: 13,
      name: "NIGER",
      threshold: 875,
      approvedUserCount: 0,
    },
    {
      id: 37,
      name: "OGUN",
      threshold: 700,
      approvedUserCount: 0,
    },
    {
      id: 24,
      name: "ONDO",
      threshold: 630,
      approvedUserCount: 0,
    },
    {
      id: 22,
      name: "OSUN",
      threshold: 1050,
      approvedUserCount: 0,
    },
    {
      id: 21,
      name: "OYO",
      threshold: 1155,
      approvedUserCount: 0,
    },
    {
      id: 16,
      name: "PLATEAU",
      threshold: 595,
      approvedUserCount: 0,
    },
    {
      id: 33,
      name: "RIVERS",
      threshold: 805,
      approvedUserCount: 0,
    },
    {
      id: 1,
      name: "SOKOTO",
      threshold: 805,
      approvedUserCount: 1,
    },
    {
      id: 17,
      name: "TARABA",
      threshold: 560,
      approvedUserCount: 0,
    },
    {
      id: 5,
      name: "YOBE",
      threshold: 595,
      approvedUserCount: 0,
    },
    {
      id: 2,
      name: "ZAMFARA",
      threshold: 490,
      approvedUserCount: 0,
    },
  ])

  // useEffect(() => {
  //   Swal.fire({
  //     title: "Fetching records...",
  //     showConfirmButton: false,
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     didOpen: () => {
  //       Swal.showLoading(Swal.getDenyButton())
  //     },
  //   })
  //   getApprovedAgents
  // }, [])

  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"
  return (
    <React.Fragment>
      {!isErr && (
        <Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title="Approved Agents Registration"
                breadcrumbItem="Approved Agents Registration"
              />

              <Row>
                {records?.map((item, key) => (
                  <Col key={"_col_" + key} md="3">
                    <Card className="mini-stats-wid">
                      <CardBody
                        style={{
                          paddingTop: 40,
                          paddingBottom: 40,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div className="text-center mb-4">
                          <img
                            src={getItemByName(
                              data,
                              String(item.name).toLowerCase()
                            )}
                            width={100}
                            height={100}
                            style={{ textAlign: "center" }}
                          />

                          <div className="row justify-content-center mt-3">
                            <div className="col-xl-10">
                              <h4 className="text-primary">{item.name}</h4>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col md="5">
                            <Row>
                              <h5
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              >
                                Threshold
                              </h5>
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                {item?.threshold.toLocaleString()}
                              </h6>
                            </Row>
                          </Col>
                          <Col md="7">
                            <Row>
                              <h5
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              >
                                Agents
                              </h5>
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                {item?.approvedUserCount.toLocaleString()}
                              </h6>
                            </Row>
                          </Col>
                        </Row>
                        <hr />

                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/agent-payments-by-lga/${
                                  item?.id
                                }/${encodeURIComponent(item?.name)}`}
                              >
                                View LGAs
                              </Link>
                            </h5>
                          </Col>
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/view-agent-payments-by-state/${item?.id}`}
                              >
                                View Agents
                              </Link>
                            </h5>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </Fragment>
      )}

      {isErr && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={errorImg} width={500} />
          <p style={{ marginTop: 5, fontSize: 18 }}>
            Error fetching records...
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default SuperAdminAgentPayment
