import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GENERAL } from "../../../api/general"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { data } from "common/data/states"
import errorImg from "assets/images/error-img.png"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"

const AgentPaymentByLga = props => {
  const [isErr, setIsErr] = useState(false)
  const params = useParams()
  console.log("params :; ", params)
  const [records, setRecords] = useState([
    {
      id: 648,
      name: "ABA  SOUTH",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 646,
      name: "ABA NORTH",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 639,
      name: "AROCHUKWU",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 637,
      name: "BENDE",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 642,
      name: "IKWUANO",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 643,
      name: "ISIALA-NGWA NORTH",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 644,
      name: "ISIALA-NGWA SOUTH",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 636,
      name: "ISIUKWUATO",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 645,
      name: "OBINGWA",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 638,
      name: "OHAFIA",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 647,
      name: "OSISIOMA NGWA",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 649,
      name: "UGWUNAGBO",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 650,
      name: "UKWA EAST",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 651,
      name: "UKWA WEST",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 640,
      name: "UMUAHIA NORTH",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 641,
      name: "UMUAHIA SOUTH",
      threshold: "35",
      approvedUserCount: 0,
    },
    {
      id: 635,
      name: "UMUNNEOCHI",
      threshold: "35",
      approvedUserCount: 0,
    },
  ])

  useEffect(() => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    getApprovedAgentsByStateId
  }, [])

  const getApprovedAgentsByStateId = useQuery({
    queryKey: ["getApprovedAgentsByStateId"],
    queryFn: () => {
      return GENERAL.getApprovedAgentsByStateId(params?.id)
    },
    onSuccess: async res => {
      Swal.close()
      console.log(
        "approved agents applicants by state_id ::",
        res.data.palliative
      )

      if (res.status === 201) {
        setIsErr(false)
        setRecords(
          res.data.palliative?.sort((a, b) => a.name.localeCompare(b.name))
        )
      }
      if (res.status === 500) {
        setIsErr(true)
      }
    },
  })
  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }
  //meta title
  document.title = "Agents Registration - eRecruitement"
  return (
    <React.Fragment>
      {!isErr && (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Row>
              <Breadcrumbs
                title={props.t("Approved Agents Registration")}
                breadcrumbItem={props.t("Approved Agents Registration By LGA")}
                hasBackBtn={true}
              />
            </Row>

            <Row>
              {records?.map((item, key) => (
                <Col key={"_col_" + key} md="3">
                  <Card className="mini-stats-wid">
                    <CardBody
                      style={{
                        paddingTop: 40,
                        paddingBottom: 40,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <div className="text-center mb-4">
                        <img
                          src={getItemByName(
                            data,
                            String(params?.state).toLowerCase()
                          )}
                          width={100}
                          height={100}
                          style={{ textAlign: "center" }}
                        />

                        <div className="row justify-content-center mt-3">
                          <div className="col-xl-10">
                            <h4 className="text-primary">{item.name}</h4>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col md="5">
                          <Row>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              Threshold
                            </h5>
                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              {item?.threshold?.toLocaleString()}
                            </h6>
                          </Row>
                        </Col>
                        <Col md="7">
                          <Row>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              Total Applications
                            </h5>
                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              {item?.approvedUserCount?.toLocaleString()}
                            </h6>
                          </Row>
                        </Col>
                      </Row>
                      <hr />

                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            <Link
                              to={`/view-agent-payments-by-lga/${item.id}`}
                            >
                              View Agents
                            </Link>
                          </h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      )}

      {isErr && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={errorImg} width={500} />
          <p style={{ marginTop: 5, fontSize: 18 }}>
            Error fetching records...
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default withTranslation()(AgentPaymentByLga)
