import React, { lazy, useEffect } from "react"
//i18n
import { GLOBAL_VARIABLES, ROLES } from "helpers/global_variables.helper"
import { withTranslation } from "react-i18next"
import { GENERAL } from "api/general"
import ErrorHandler from "helpers/error.helper"
import { useQuery } from "react-query"

const SuperAdminDashboard = lazy(() => import("./SuperAdminDashboard"))
const SupervisorDashboard = lazy(() => import("./SupervisorDashboard"))
const RegionalCoordinatorDashboard = lazy(() =>
  import("./RegionalCoordinatorDashboard")
)
const StateCoordinatorDashboard = lazy(() =>
  import("./StateCoordinatorDashboard")
)
const LgaCoordinatorDashboard = lazy(() => import("./LgaCoordinatorDashboard"))

const Dashboard = () => {
  //meta title
  document.title = "Dashboard | Palliative Admin Dashboard"

  const userRole = JSON.parse(sessionStorage.getItem("userRole"))

  // cache bank info
  const { } = useQuery(["banks-info"], {
    queryFn: () => GENERAL.getBanksInfo(),
    onSuccess: (data) => {
      sessionStorage.setItem(GLOBAL_VARIABLES.banks_info, JSON.stringify(data))
    }
  });

  return (
    <>
      {userRole === ROLES.superadmin && <SuperAdminDashboard />}
      {userRole === ROLES.supervisor && <SupervisorDashboard />}
      {userRole === ROLES.regional_coordinator && (
        <RegionalCoordinatorDashboard />
      )}
      {userRole === ROLES.state_coordinator && <StateCoordinatorDashboard />}
      {userRole === ROLES.lga_coordinator && <LgaCoordinatorDashboard />}
    </>
  )
}

export default withTranslation()(Dashboard)
