import { get, post, put } from "../services"
import { getKey } from "../helpers"

export const GENERAL = {
  getDashboardStatistics: async () => {
    let token = await getKey("token")
    let data = await get(`admin/getDashboardStats`)

    return data
  },
  getBeneficiariesByState: async () => {
    let data = await get(`beneficiaries/group-by-state`)
    return data
  },
  getBanks: async () => {
    let data = await get(`banks`)
    return data
  },
  getBeneficiaryByBeneficiaryId: async beneficiary_id => {
    let { data } = await get(`beneficiaries?beneficiary_id=${beneficiary_id}`)
    return data
  },
  getBeneficiaryById: async beneficiary_id => {
    let data = await get(`beneficiaries/${beneficiary_id}`)
    return data
  },
  getBeneficiariesByStateId: async payload => {
    let data = await get(`beneficiaries/get-all-by-state`, payload)

    return data
  },
  getBeneficiariesByLga: async payload => {
    let data = await get(`beneficiaries/group-by-lga?state=${payload}`)

    return data
  },
  getBeneficiariesByLgaId: async payload => {
    let data = await get(`beneficiaries/get-all-by-lga`, { ...payload })

    return data
  },
  migrateBeneficiaries: async (agent_from, agent_to) => {
    let data = await get(
      `beneficiaries/trasfer-beneficiaries?agent_from=${agent_from}&agent_to=${agent_to}`
    )

    return data
  },
  getVerifications: async params => {
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== "")
    )
    let data = await get(`verifications/get-verifications`, {
      ...filteredParams,
    })

    return data
  },
  getVerifiedBeneficiaryCount: async () => {
    let data = await get(`verifications/verified-beneficiary-count`)

    return data
  },
  getUnverifiedBeneficiaryCount: async () => {
    let data = await get(`verifications/unverified-beneficiary-count`)

    return data
  },
  getUsersByRole: async params => {
    let data = await get(`users/get-users`, { ...params })

    return data
  },
  getUser: async () => {
    let data = await get(`users/me`)
    return data
  },
  getAgents: async payload => {
    let data = await get(
      `users/get-users?role=${payload.role}&state=${payload.state}`
    )

    return data
  },
  getUserById: async payload => {
    let { data } = await get(`users?id=${payload.id}`)
    return data
  },

  deactivateUser: async payload => {
    let { data } = await get(`users/deactivate-user`, payload)
    return data
  },
  activateUser: async payload => {
    let { data } = await get(`users/activate-user`, payload)
    return data
  },
  getEligibiltyRecords: async () => {
    let data = await get(`eligibilities`)

    return data
  },
  createEligibilityRecord: async payload => {
    let data = await post(`eligibilities`, payload)

    return data
  },
  updateEligibilityRecord: async payload => {
    let data = await put(`eligibilities/update-eligibility`, payload)

    return data
  },
  deactivateEligibility: async id => {
    let data = await get(`eligibilities/deactivate-eligibility?id=${id}`)

    return data
  },
  activateEligibility: async id => {
    let data = await get(`eligibilities/activate-eligibility?id=${id}`)

    return data
  },
  getProgramms: async payload => {
    let data = await get(`programmes`, payload)

    return data
  },
  createProgramme: async payload => {
    let data = await post(`programmes`, payload)

    return data
  },

  runEligibilityCheck: async payload => {
    let data = await post(`/beneficiaries/check-eligibility`, payload)
    return data
  },

  updateEligibilityStatus: async payload => {
    let data = await post(`/beneficiaries/update-eligibility-status`, payload)

    return data
  },

  ninVerifyFn: async payload => {
    let data = await post(`beneficiaries/verify-nin`, payload)

    return data
  },

  authVerifyFn: async payload => {
    let data = await post(`auth/verify-nin`, payload)

    return data
  },

  getStatesAndLgas: async payload => {
    let data = await get(`/common/get-states-and-lgas`, payload)

    return data
  },

  deactivateProgram: async payload => {
    let data = await get(`programmes/deactivate-program?id=${payload}`)

    return data
  },

  activateProgram: async payload => {
    let data = await get(`programmes/activate-program?id=${payload}`)

    return data
  },

  getVerificationsGroupByState: async params => {
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== "")
    )
    let data = await get(`/verifications/group-by-state`, {
      ...filteredParams,
    })

    return data
  },
  getVerificationsByState: async payload => {
    let data = await get(
      `/verifications/get-all-by-state?state=${payload.state}`
    )

    return data
  },
  getVerificationsGroupByLga: async payload => {
    let data = await get(`/verifications/group-by-lga?state=${payload}`)

    return data
  },
  getVerificationsByLga: async payload => {
    let data = await get(`/verifications/get-all-by-lga?lga=${payload?.lga}`)

    return data
  },
  paySingleBeneficiary: async payload => {
    let data = await post(`/payment/make-payment-to-beneficiary`, payload)

    return data
  },
  payAllState: async payload => {
    let data = await post(
      `/payment/make-payment-to-beneficiaries-by-state`,
      payload
    )

    return data
  },
  payAllLga: async payload => {
    let data = await post(
      `/payment/make-payment-to-beneficiaries-by-lga`,
      payload
    )

    return data
  },
  buildReportFn: async payload => {
    let endpoint
    if (
      payload.menu &&
      payload.category &&
      payload.state &&
      payload.lga &&
      payload.dateFrom &&
      payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&state=${payload.state}&lga=${payload.lga}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}`
    }
    if (
      payload.menu &&
      payload.category &&
      !payload.state &&
      !payload.lga &&
      !payload.dateFrom &&
      !payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}`
    }
    if (
      payload.menu &&
      payload.category &&
      payload.state &&
      payload.lga &&
      !payload.dateFrom &&
      !payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&state=${payload.state}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}`
    }
    if (
      payload.menu &&
      payload.category &&
      payload.state &&
      payload.lga &&
      payload.dateFrom &&
      !payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&state=${payload.state}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}`
    }
    // state options
    if (
      payload.menu &&
      payload.category &&
      payload.state &&
      !payload.lga &&
      payload.dateFrom &&
      payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&state=${payload.state}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}`
    }
    if (
      payload.menu &&
      payload.category &&
      payload.state &&
      !payload.lga &&
      payload.dateFrom &&
      !payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&state=${payload.state}&dateFrom=${payload.dateFrom}`
    }
    if (
      payload.menu &&
      payload.category &&
      payload.state &&
      !payload.lga &&
      !payload.dateFrom &&
      !payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&state=${payload.state}`
    }
    // lga options
    if (
      payload.menu &&
      payload.category &&
      !payload.state &&
      payload.lga &&
      payload.dateFrom &&
      payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&lga=${payload.lga}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}`
    }
    if (
      payload.menu &&
      payload.category &&
      !payload.state &&
      payload.lga &&
      payload.dateFrom &&
      !payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&lga=${payload.lga}&dateFrom=${payload.dateFrom}`
    }
    if (
      payload.menu &&
      payload.category &&
      !payload.state &&
      payload.lga &&
      !payload.dateFrom &&
      !payload.dateTo
    ) {
      endpoint = `/common/report-builder?menu=${payload.menu}&category=${payload.category}&lga=${payload.lga}`
    }
    let data = await get(endpoint)

    return data
  },

  beneficiaryiesByAgent: async params => {
    let data = await get("/beneficiaries/get-all-by-agent", { ...params })
    return data
  },

  getBeneficiaryVerifications: async params => {
    let data = await get("/verifications/get-verifications", { ...params })
    return data
  },
  getBanksInfo: async () => {
    let data = await get("/banks")
    return data
  },
  getPaymentVerificationCount: async params => {
    let data = await get("/stats/verification-count", { ...params })
    return data
  },
  getBeneficiaryByCategory: async params => {
    let data = await get("stats/beneficiary-by-category")
    return data
  },
}
