import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { useTable, useAsyncDebounce, useRowSelect } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useParams } from "react-router"
import { GENERAL } from "../../../api/general"
import Swal from "sweetalert2"
import dayjs from "dayjs"
import PaginationComponent from "components/Common/pagination"
import ActivityLoader from "components/activity-loader"
import useDebounce from "helpers/hooks/useDebounce"
import { API_RECORD_LIST_SIZE } from "helpers/global_variables.helper"
import { MoreHorizontal } from "lucide-react"

const ViewBeneficiaryByState = props => {
  //meta title
  document.title = "Agents Registration - eRecruitement"
  const params = useParams()
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(1)
  const [data, setRecords] = useState([])
  const [filterOption, setFilterOption] = React.useState("")
  const [searchFilter, setSearchFilter] = React.useState("")
  const onChange = value => {
    setSearchFilter(value)
  }
  const { value: searchQuery } = useDebounce(searchFilter, 1000)

  const getBeneficiariesByStateId = useQuery({
    queryKey: [
      "getBeneficiariesByStateId",
      {
        state: params.id,
        searchQuery,
        category: filterOption,
        currentIndex,
      },
    ],
    queryFn: () => {
      return GENERAL.getBeneficiariesByStateId({
        state: params?.id,
        page: currentIndex,
        take: API_RECORD_LIST_SIZE,
        search: searchFilter,
        category: filterOption,
      })
    },
    keepPreviousData: true,
    onSuccess: async res => {
      setRecords(res?.records)
    },
  })
  const gotoProfile = record => {
    const beneficiaryId = record.cell.row.original.beneficiaryId
    navigate(`/beneficiary-profile/${beneficiaryId}`)
  }

  const getSns = useMemo(() => {
    const offset = (currentIndex - 1) * API_RECORD_LIST_SIZE
    return data?.map((_, index) => index + offset + 1)
  }, [currentIndex, data, API_RECORD_LIST_SIZE])

  const columns = useMemo(() => {
    switch (filterOption) {
      case "beneficiary_without_nin":
        return [
          {
            Header: "S/N",
            Cell: cell => {
              const serialNumber = getSns[cell.row.index]
              return <FieldFormat value={serialNumber} />
            },
          },
          {
            Header: "Full Name",
            accessor: "fullname",
            filterable: false,
            disableFilters: true,
            Cell: cellProps => {
              return <FullnameFormat {...cellProps} />
            },
          },

          {
            Header: "Beneficiary ID",
            accessor: "beneficiaryId",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },

          {
            Header: "Phone Number",
            accessor: "phoneNumber",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },
          {
            Header: "NIN Status",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <NINFormat {...cellProps} />
            },
          },
          {
            Header: "Eligibility Status",
            accessor: "eligibilityStatus",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },
          {
            Header: "Eligibility Check Count",
            accessor: "eligibilityCheckCount",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <p>{cellProps.value.toString()}</p>
            },
          },
          {
            Header: "Registration Date",
            accessor: "createdAt",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <DateFormat {...cellProps} />
            },
          },
          // {
          //   Header: "Eligibility Check",
          //   disableFilters: true,
          //   Cell: cellProps => {
          //     return <RunEligibilityCheckBtn {...cellProps} />
          //   },
          // },
          // {
          //   Header: "Eligibility bypass",
          //   disableFilters: true,
          //   Cell: cellProps => {
          //     return <RunEligibilityByPass {...cellProps} />
          //   },
          // },
          // {
          //   Header: "",
          //   disableFilters: true,
          //   accessor: "view",
          //   Cell: cellProps => {
          //     return (
          //       <Button
          //         type="button"
          //         color="primary"
          //         className="btn-sm btn-rounded"
          //         onClick={() => {
          //           gotoProfile(cellProps)
          //         }}
          //       >
          //         View
          //       </Button>
          //     )
          //   },
          // },

          {
            id: "actions",
            Cell: cellProps => {
              const [dropdownOpen, setDropdownOpen] = useState(false)
              return (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => {
                    setDropdownOpen(!dropdownOpen)
                  }}
                >
                  <DropdownToggle
                    style={{ cursor: "pointer" }}
                    data-toggle="dropdown"
                    tag="span"
                  >
                    <MoreHorizontal />
                  </DropdownToggle>
                  <DropdownMenu className="border ">
                    <DropdownItem className="w-100">
                      <RunEligibilityByPass {...cellProps} />
                    </DropdownItem>
                    <DropdownItem>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded w-100"
                        onClick={() => {
                          gotoProfile(cellProps)
                        }}
                      >
                        View
                      </Button>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )
            },
          },
        ]
      case "ineligible":
        return [
          {
            Header: "S/N",
            Cell: cell => {
              const serialNumber = getSns[cell.row.index]
              return <FieldFormat value={serialNumber} />
            },
          },
          {
            Header: "Full Name",
            accessor: "fullname",
            filterable: false,
            disableFilters: true,
            Cell: cellProps => {
              return <FullnameFormat {...cellProps} />
            },
          },
          {
            Header: "NIN/Tracking ID",
            accessor: "nin",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <NINFormat {...cellProps} />
            },
          },

          {
            Header: "Phone Number",
            accessor: "phoneNumber",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },
          {
            Header: "Reason",
            accessor: "bypassReason",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },
          // {
          //   Header: "Eligibility bypass",
          //   disableFilters: true,
          //   Cell: cellProps => {
          //     return <RunEligibilityByPass {...cellProps} />
          //   },
          // },

          // {
          //   Header: "",
          //   disableFilters: true,
          //   accessor: "view",
          //   Cell: cellProps => {
          //     return (
          //       <Button
          //         type="button"
          //         color="primary"
          //         className="btn-sm btn-rounded"
          //         onClick={() => {
          //           gotoProfile(cellProps)
          //         }}
          //       >
          //         View
          //       </Button>
          //     )
          //   },
          // },

          {
            id: "actions",
            Cell: cellProps => {
              const [dropdownOpen, setDropdownOpen] = useState(false)
              return (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => {
                    setDropdownOpen(!dropdownOpen)
                  }}
                >
                  <DropdownToggle
                    style={{ cursor: "pointer" }}
                    data-toggle="dropdown"
                    tag="span"
                  >
                    <MoreHorizontal />
                  </DropdownToggle>
                  <DropdownMenu className="border ">
                    <DropdownItem className="w-100">
                      <RunEligibilityByPass {...cellProps} />
                    </DropdownItem>
                    <DropdownItem>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded w-100"
                        onClick={() => {
                          gotoProfile(cellProps)
                        }}
                      >
                        View
                      </Button>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )
            },
          },
        ]
      default:
        return [
          {
            Header: "S/N",
            Cell: cell => {
              const serialNumber = getSns[cell.row.index]
              return <FieldFormat value={serialNumber} />
            },
          },
          {
            Header: "Full Name",
            // accessor: "fullname",
            filterable: false,
            disableFilters: true,
            Cell: cellProps => {
              return <FullnameFormat {...cellProps} />
            },
          },

          {
            Header: "Beneficiary ID",
            accessor: "beneficiaryId",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },
          {
            Header: "No of Dependants",
            accessor: "",
            filterable: false,
            disableFilters: true,
            Cell: cell => {
              const noOfDependants =
                cell.row.original?.dependentBeneficiaries.length
              return <p>{noOfDependants}</p>
            },
          },
          {
            Header: "NIN/Tracking ID",
            accessor: "nin",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <NINFormat {...cellProps} />
            },
          },
          {
            Header: "Phone Number",
            accessor: "phoneNumber",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },
          {
            Header: "Wallet Status",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <WalletFormat {...cellProps} />
            },
          },
          {
            Header: "State",
            accessor: "stateOfOrigin",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },

          {
            Header: "Eligibility Status",
            accessor: "eligibilityStatus",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },

          {
            Header: "Registration Type",
            accessor: "channel",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <FieldFormat {...cellProps} />
            },
          },
          {
            Header: "Registration Date",
            accessor: "createdAt",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <DateFormat {...cellProps} />
            },
          },

          {
            id: "actions",
            Cell: cellProps => {
              const [dropdownOpen, setDropdownOpen] = useState(false)
              return (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => {
                    setDropdownOpen(!dropdownOpen)
                  }}
                >
                  <DropdownToggle
                    style={{ cursor: "pointer" }}
                    data-toggle="dropdown"
                    tag="span"
                  >
                    <MoreHorizontal />
                  </DropdownToggle>
                  <DropdownMenu className="border ">
                    <DropdownItem className="w-100">
                      <RunEligibilityCheckBtn {...cellProps} />
                    </DropdownItem>
                    <DropdownItem className="w-100">
                      <RunEligibilityByPass {...cellProps} />
                    </DropdownItem>
                    <DropdownItem>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded w-100"
                        onClick={() => {
                          gotoProfile(cellProps)
                        }}
                      >
                        View
                      </Button>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )
            },
          },
        ]
    }
  }, [data, filterOption, currentIndex])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      initialState: {
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    })

  const RunEligibilityByPass = cell => {
    const record = cell.cell.row.original
    const [bypassReason, setBypassReason] = useState("")

    const handleBypassConfirmation = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "This process cannot be reversed!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, bypass!",
        html: `<textarea id="bypassReason" placeholder="Enter reason..." class="swal2-textarea" >${bypassReason}</textarea>
      `,
        preConfirm: () => {
          const updatedBypassReason =
            document.getElementById("bypassReason").value
          if (!updatedBypassReason) {
            Swal.showValidationMessage("Reason cannot be empty")
            return false
          }
          setBypassReason(updatedBypassReason)
          return updatedBypassReason
        },
      }).then(result => {
        if (result.isConfirmed) {
          const updatedBypassReason = result.value
          Swal.fire({
            title: "Updating Status...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(Swal.getDenyButton())
            },
          })
          const updateStatusDto = {
            beneficiaryId: record.beneficiaryId,
            status: "bypassed",
            bypassReason: updatedBypassReason,
          }
          updateEligibilityStatus.mutate(updateStatusDto)
        }
      })
    }
    if (
      record?.eligibilityStatus === "pending" ||
      record?.eligibilityStatus === "ineligible"
    ) {
      return (
        <Button
          type="button"
          color="danger"
          className="btn-sm w-100 btn-rounded"
          onClick={handleBypassConfirmation}
        >
          Run bypass
        </Button>
      )
    } else {
      return (
        <Button
          type="button"
          color="danger"
          className="btn-sm w-100 btn-rounded"
          disabled
        >
          Run By Pass
        </Button>
      )
    }
  }

  const RunEligibilityCheckBtn = cell => {
    const record = cell?.row.original
    if (record.nin && record?.eligibilityStatus === "pending") {
      return (
        <Button
          type="button"
          color="primary"
          className="btn-sm w-100 btn-rounded"
          onClick={() => {
            Swal.fire({
              title: "Running Eligibility Check...",
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading(Swal.getDenyButton())
              },
            })
            const runEligibilityCheckDto = {
              bvn: record.nin,
              phoneNumber: record.phoneNumber,
            }
            runEligibilityCheck.mutate({
              ...runEligibilityCheckDto,
              beneficiaryId: record.beneficiaryId,
            })
          }}
        >
          Run Eligibility
        </Button>
      )
    } else {
      return (
        <Button
          type="button"
          color="primary"
          className="btn-sm w-100 btn-rounded"
          disabled
        >
          Run Eligibility
        </Button>
      )
    }
  }

  const runEligibilityCheck = useMutation({
    mutationFn: async ({ bvn, phoneNumber, beneficiaryId }) => {
      const response = await GENERAL.runEligibilityCheck({ bvn, phoneNumber })
      return response
    },
    onSuccess: async (response, variables) => {
      Swal.close()
      if (response.status === 201) {
        Swal.fire({
          title: "Process Successful",
          text: response.data.message,
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(res => {
          if (res.isConfirmed) {
            Swal.fire({
              title: "Updating Status...",
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading(Swal.getDenyButton())
              },
            })
            const updateStatusDto = {
              beneficiaryId: variables.beneficiaryId,
              status: "eligible",
            }
            updateEligibilityStatus.mutate(updateStatusDto)
          }
        })
      }
    },
    onError: async (error, variables) => {
      console.log("error", variables)
      Swal.fire({
        title: "Error",
        text: error.data.message,
        icon: "error",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(res => {
        if (res.isConfirmed) {
          Swal.fire({
            title: "Updating Status...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(Swal.getDenyButton())
            },
          })
          const updateStatusDto = {
            beneficiaryId: variables.beneficiaryId,
            status: "ineligible",
          }

          updateEligibilityStatus.mutate(updateStatusDto)
        }
      })
    },
  })

  const updateEligibilityStatus = useMutation({
    mutationFn: payload => {
      return GENERAL.updateEligibilityStatus(payload)
    },
    onSuccess: async response => {
      Swal.close()
      getBeneficiariesByStateId.refetch()
      Swal.fire("Process Successful", response.data.message, "success")
    },
    onError: async error => {
      Swal.close()
      Swal.fire({
        title: "",
        html: Array.isArray(error.data.message)
          ? error.data.message.join("<br>")
          : error.data.message,
        icon: "error",
      })
    },
  })

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const WalletFormat = cell => {
    const { accountNumber, walletNumber } = cell.cell.row.original || {}
    if (accountNumber) return <p>N/A</p>
    else if (walletNumber) {
      return <p className="text-success">Created</p>
    }
    return null
  }

  const NINFormat = cell => {
    const NIN = cell.cell.row.original ? cell.cell.row.original.nin : ""
    const TrackingId = cell.cell.row.original
      ? cell.cell.row.original?.beneficiaryNin?.trackingId
      : ""

    if (NIN) {
      return <p>{NIN}</p>
    } else {
      return <p>{TrackingId}</p>
    }
  }

  const FullnameFormat = record => {
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.firstName ?? ""} ${rowRecord?.middleName ?? ""} ${
      rowRecord?.surname ?? ""
    }`
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const filterOptions = [
    { id: "", name: "All Beneficiaries" },
    { id: "beneficiary_with_nin", name: "Beneficiaries With NIN" },
    { id: "beneficiary_without_nin", name: "Beneficiaries Without NIN" },
    // { id: "updated", name: "Updated Beneficiaries" },
    // { id: "enrolled", name: "Enrolled Beneficiaries" },
    { id: "ineligible", name: "Ineligible Applicants" },
    { id: "principal", name: "Principal Beneficiaries" },
    { id: "dependant", name: "Dependant Beneficiaries" },
    // { id: "bypassed", name: "Bypassed Applicants" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Breadcrumbs
              parentLink={props.t("Beneficiaries")}
              childLink={props.t("State")}
              thirdLink={props.t("All Benediciaries")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={5}>
                  <div className="mb-4 h4 card-title">
                    All Beneficiaries - {data.length}
                  </div>
                </Col>

                <Col md={7} className="mb-2">
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col md={5}>
                      <div className="search-box me-xxl-4 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`search`}
                              value={searchFilter || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            // onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          ></div>
                        </div>
                      </div>
                    </Col>
                    <Col md={7}>
                      <select
                        onChange={({ target }) => setFilterOption(target.value)}
                        className="form-select"
                        value={filterOption}
                      >
                        {/* <option key="0" value="0">
                        All States
                      </option> */}
                        {filterOptions?.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </Col>
              </div>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {getBeneficiariesByStateId.isLoading ||
                      getBeneficiariesByStateId.isFetching ? (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            <ActivityLoader variant="primary" />
                          </td>
                        </tr>
                      ) : rows.length ? (
                        rows.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr key={i} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columns.length} className=" text-center">
                            No results.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <PaginationComponent
                    maxPages={Math.ceil(
                      (getBeneficiariesByStateId?.data?.total || 0) /
                        API_RECORD_LIST_SIZE
                    )}
                    getCurrentPage={setCurrentIndex}
                    page={currentIndex}
                  />
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewBeneficiaryByState)
