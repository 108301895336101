import React, { Fragment, useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { GENERAL } from "../../../api/general"
import { useQuery } from "react-query"
import { data, states } from "common/data/states"
import errorImg from "assets/images/error-img.png"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Swal from "sweetalert2"
import BackButton from "components/Common/BackButton"

const SuperAdminBeneficiaries = () => {
  const [isErr, setIsErr] = useState(false)
  const [records, setRecords] = useState([])
  const statesData = states;

  useEffect(() => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    getBeneficiariesByState
  }, [])

  const getBeneficiariesByState = useQuery({
    queryKey: ["getBeneficiariesByState"],
    queryFn: () => {
      return GENERAL.getBeneficiariesByState()
    },
    onSuccess: async res => {
      Swal.close()
      console.log("getBeneficiariesByState response ::", res)

      // Create a mapping object from array A with lowercase names
      const aMapping = res.reduce((acc, item) => {
        acc[item.state.toLowerCase()] = item
        return acc
      }, {})

      // Create a new array by replacing occurrences from array B with items from array A
      const newArray = statesData.map(itemB => {
        const matchedItem = aMapping[itemB.state.toLowerCase()]
        return matchedItem ? { ...itemB, ...matchedItem } : itemB
      })

      console.log("newArray ::=> ", newArray)
      setRecords(newArray.sort((a, b) => a.state.localeCompare(b.state)))
    },
  })

  function getItemByName(arr, name) {
    return arr.find(item => String(item?.name).toLowerCase() === name)?.logo
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"
  return (
    <React.Fragment>
      {!isErr && (
        <Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs parentLink="Beneficiaries" childLink="State" />
              <BackButton hasBackBtn={true} />

              <Row>
                {records?.map((item, key) => (
                  <Col key={"_col_" + key} md="4" lg="3">
                    <Card className="mini-stats-wid">
                      <CardBody
                        style={{
                          paddingTop: 40,
                          paddingBottom: 40,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div className="text-center mb-4">
                          <img
                            src={getItemByName(
                              data,
                              String(item?.state).toLowerCase()
                            )}
                            width={100}
                            height={100}
                            style={{ textAlign: "center" }}
                          />

                          <div className="row justify-content-center mt-3">
                            <div className="col-xl-10">
                              <h4 className="text-primary">{String(item.state).toUpperCase()}</h4>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col md="5">
                            <Row>
                              <h5
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              >
                                Threshold
                              </h5>
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#000",
                                  background: "#bcebca",
                                  padding: "10px",
                                  marginLeft: "20%",
                                  width: "70%",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* {item?.threshold.toLocaleString()} */}
                                300,000
                              </h6>
                            </Row>
                          </Col>
                          <Col md="7">
                            <Row>
                              <h5
                                style={{
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              >
                                Beneficiary
                              </h5>
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  color: "#000",
                                  background: "#bcebca",
                                  padding: "10px",
                                  marginLeft: "20%",
                                  width: "70%",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* {item?.registeredBeneficiaries.toLocaleString()} */}
                                {item?.count.toLocaleString()}
                              </h6>
                            </Row>
                          </Col>
                        </Row>
                        <hr />

                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/beneficiary-by-lga/${
                                  item?.id
                                }/${encodeURIComponent(item?.state)}`}
                              >
                                View LGAs
                              </Link>
                            </h5>
                          </Col>
                          <Col>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              <Link
                                to={`/view-beneficiary-by-state/${item?.state}`}
                              >
                                View Beneficiary
                              </Link>
                            </h5>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </Fragment>
      )}

      {isErr && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={errorImg} width={500} />
          <p style={{ marginTop: 5, fontSize: 18 }}>
            Error fetching records...
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default SuperAdminBeneficiaries
