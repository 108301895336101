import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Table,
  Form,
  Input,
  Label,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { GENERAL } from "../../api/general"
import Swal from "sweetalert2"
import dayjs from "dayjs"

const ReportBuilder = props => {
  useEffect(() => {
    getStatesAndLgas
  }, [])

  const [menuTitle, setMenuTitle] = React.useState("")
  const [selectedMenu, setSelectedMenu] = React.useState(null)
  const [selectedCategory, setSelectedCategory] = React.useState(null)
  const [selectedState, setSelectedState] = React.useState(null)
  const [selectedLga, setSelectedLga] = React.useState(null)
  const [dateFrom, setDateFrom] = React.useState(null)
  const [dateTo, setDateTo] = React.useState(null)
  const [statesAndLgas, setStatesAndLgas] = React.useState([])
  const [lgas, setLgas] = React.useState([])

  // const params = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [apiPageSize, setApiPageSize] = useState(10)
  const [data, setRecords] = useState([])

  const count = 0
  const [value, setValue] = React.useState("")
  const [filter, setFiilter] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const handleSearchFn = () => {
    console.log("this is the search keyword :: ", filter, value)
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const pageDto = {
      searchKey: filter,
      list_id: userInfo?.lga,
      page: 1,
      pageSize: 20,
    }
    // getRecordsByLgaId.mutate(pageDto)
  }

  const getStatesAndLgas = useQuery({
    queryKey: ["getStatesAndLgas"],
    queryFn: () => {
      return GENERAL.getStatesAndLgas()
    },
    onSuccess: async res => {
      console.log("get all statest response ::", res)
      setStatesAndLgas(res)
    },
  })

  //meta title
  document.title = "Agents Registration - eRecruitement"
  const customPageSize = 6

  const gotoPreviousPage = () => {
    console.log("fetching next page...")
    setPage(page - 1)
  }
  const gotoNextPage = () => {
    console.log("fetching next page...")
    setPage(page + 1)
  }

  const beneficiaryColumns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "fullname",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiary_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Tracking ID",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "eligibility_status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Category",
        accessor: "category",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                gotoProfile()
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )
  const verificationColumns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "fullname",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FullnameFormat {...cellProps} />
        },
      },
      {
        Header: "Beneficiary ID",
        accessor: "beneficiary_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Tracking ID",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "eligibility_status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Category",
        accessor: "category",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
    ],
    []
  )

  const gotoProfile = () => {
    navigate("/profile")
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns:
      selectedMenu === "beneficiary" ? beneficiaryColumns : verificationColumns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const FullnameFormat = record => {
    console.log("this is the cell record :: ", record?.cell?.row?.original)
    const rowRecord = record?.cell?.row?.original
    return `${rowRecord?.first_name ?? ""} ${rowRecord?.middle_name ?? ""} ${
      rowRecord?.surname ?? ""
    }`
  }

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const menuOptions = [
    { id: "", name: "Select Menu" },
    { id: "beneficiaries", name: "Beneficiary" },
    { id: "verifications", name: "Verifications" },
    // { id: "payment", name: "Payment" },
    // { id: "agent", name: "Agent" },
  ]

  const categoryOptions = [
    { id: "", name: "Select Category" },
    { id: "beneficiaries_with_nin", name: "Beneficiaries With NIN" },
    { id: "beneficiaries_without_nin", name: "Beneficiaries Without NIN" },
    { id: "ineligible_applicants", name: "Ineligible Applicants" },
  ]

  const handleSelectMenu = evt => {
    setSelectedMenu(evt.target.value)
  }
  const handleSelectCategory = evt => {
    console.log("this is the selected category :: ", evt.target.value)
    setSelectedCategory(evt.target.value)
  }

  const handleSelectState = evt => {
    console.log("this is the selected state :: ", evt.target.value)
    const state = evt.target.value
    setSelectedState(evt.target.value)

    Swal.fire({
      title: "Fetching LGAs...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    Swal.close()
    const lgas = statesAndLgas.find(
      item => item?.state.toLowerCase() === state.toLowerCase()
    ).lgas
    setLgas(lgas)
  }

  const handleSelectLga = evt => {
    console.log("this is the selected state :: ", evt.target.value)
    const state = evt.target.value
    setSelectedLga(evt.target.value)
  }

  const handleSubmit = () => {
    if (!selectedMenu) {
      return Swal.fire("", "Please select a menu", "error")
    }
    if (!selectedCategory) {
      return Swal.fire("", "Please select a category", "error")
    }
    if (!dateFrom && dateTo) {
      return Swal.fire("", "Date from is required", "error")
    }
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    setTimeout(() => {
      Swal.close()
    }, 2000)

    const filterDto = {
      menu: selectedMenu,
      category: selectedCategory,
      state: selectedState,
      lga: selectedLga,
      dateFrom: dateFrom,
      dateTo: dateTo,
    }

    console.log("filterDto :: ", filterDto)
    buildReportFn.mutate(filterDto)
  }
  const buildReportFn = useMutation({
    mutationFn: payload => {
      return GENERAL.buildReportFn(payload)
    },
    onSuccess: async response => {
      Swal.close()
      console.log("buildReportFn response :: ", response)
      setMenuTitle(selectedMenu)
      setRecords(response)
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Beneficiaries")}
              childLink={props.t("State")}
              thirdLink={props.t("All Benediciaries")}
              hasBackBtn={true}
            />
          </Row>

          <Row>
            <Col md={12}>
              <Card className="large-stats-wid">
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Col lg={8} md={12}>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Menu</Label>
                            <div className="col-md-12">
                              <select
                                onChange={handleSelectMenu}
                                className="form-select"
                                value={selectedMenu}
                              >
                                {menuOptions?.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">
                              Category
                            </Label>
                            <div className="col-md-12">
                              <select
                                onChange={handleSelectCategory}
                                className="form-select"
                                value={selectedCategory}
                              >
                                {categoryOptions?.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">State</Label>
                            <div className="col-md-12">
                              <select
                                onChange={handleSelectState}
                                className="form-select"
                                value={selectedState}
                              >
                                <option>Select State</option>
                                {statesAndLgas?.map((item, index) => (
                                  <option key={index} value={item.state}>
                                    {item.state}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">LGA</Label>
                            <div className="col-md-12">
                              <select
                                onChange={handleSelectLga}
                                className="form-select"
                                value={selectedLga}
                              >
                                <option>Select LGA</option>
                                {lgas?.map((item, index) => (
                                  <option key={index} value={item.lga_name}>
                                    {item.lga_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">
                              Date From
                            </Label>
                            <div className="col-md-12">
                              <Input
                                type="date"
                                placeholder="Enter Date From"
                                name="dateFrom"
                                value={dateFrom}
                                onChange={evt => setDateFrom(evt.target.value)}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Date To</Label>
                            <div className="col-md-12">
                              <Input
                                type="date"
                                placeholder="Enter Date To"
                                name="dateFrom"
                                value={dateTo}
                                onChange={evt => setDateTo(evt.target.value)}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="mt-3 d-grid">
                        {/* {validateEmail.isLoading && (
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Processing
                        </button>
                      )} */}
                        {/* {!validateEmail.isLoading && ( */}
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Search Records
                        </button>
                        {/* )} */}
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col md={9}>
                  <div className="mb-4 h4 card-title">
                    All {menuTitle === "beneficiaries" && "Beneficiaries"}
                    {menuTitle === "verifications" && "Verifications"}-{" "}
                    {data.length}
                  </div>
                </Col>

                <Col md={3} className="mb-2">
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col md={12}>
                      <div className="search-box me-xxl-4 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => {
                                setValue(e.target.value)
                                onChange(e.target.value)
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`${count} records...`}
                              value={value || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={gotoPreviousPage}
                        disabled={page == 1}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page <strong>{page}</strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button color="primary" onClick={gotoNextPage}>
                        {">"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ReportBuilder)
