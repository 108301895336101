export const ROLES = Object.freeze({
  superadmin: "SUPER_ADMIN",
  supervisor: "SUPERVISOR",
  state_coordinator: "STATE_COORDINATOR",
  lga_coordinator: "LGA_COORDINATOR",
  regional_coordinator: "REGIONAL_COORDINATOR",
  agent: "agent",
})

export const API_RECORD_LIST_SIZE = 10;

export const API_LIST_ORDER = Object.freeze({
  asc: "ASC",
  desc: "DESC"
})

export const GLOBAL_VARIABLES = Object.freeze({
  starting_year: 2023, 
  banks_info: "banks-info"
})
export const USER_STATUS = Object.freeze({
  NEW: "new", 
  LOCKED: "locked"
})