import React, { useMemo, Fragment, useState } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { useTable, useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useParams } from "react-router"
import { GENERAL } from "../../../api/general"
import Swal from "sweetalert2"

const ViewBeneficiaryPaymentsByLga = props => {
  const params = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [apiPageSize, setApiPageSize] = useState(10)
  const [data, setRecords] = useState([
    // {
    //   id: 1,
    //   fullname: "John Smith",
    //   nin: "12345678900",
    //   phone: "12345678900",
    //   status: "active",
    //   state: "Abuja",
    //   lga: "FCT",
    //   bank: "First Bank",
    //   bankNumber: "12345678900",
    //   created_at: "2015-09-12",
    // },
  ])

  const count = 0
  const [value, setValue] = React.useState("")
  const [filter, setFiilter] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const handleSearchFn = () => {
    console.log("this is the search keyword :: ", filter, value)
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const pageDto = {
      searchKey: filter,
      list_id: userInfo?.lga,
      page: 1,
      pageSize: 20,
    }
    // getRecordsByLgaId.mutate(pageDto)
  }

  const getVerificationsByLga = useQuery({
    queryKey: ["getVerificationsByLga"],
    queryFn: () => {
      return GENERAL.getVerificationsByLga({
        lga: params?.lga,
        page: page,
        pageSize: apiPageSize,
      })
    },
    onSuccess: async res => {
      console.log("getVerificationsByLga response ::", res)
      setRecords(res?.data)
    },
  })

  //meta title
  document.title = "Agents Registration - eRecruitement"
  const customPageSize = 6

  const gotoPreviousPage = () => {
    console.log("fetching next page...")
    setPage(page - 1)
  }
  const gotoNextPage = () => {
    console.log("fetching next page...")
    setPage(page + 1)
  }

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "beneficiary",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FormatBold {...cellProps} />
        },
      },
      {
        Header: "NIN",
        accessor: "nin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga_of_origin",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Bank",
        accessor: "bank",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Account Number",
        accessor: "bankNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Month",
        accessor: "month",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      {
        Header: "Year",
        accessor: "year",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FieldFormat {...cellProps} />
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "verification_status",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     return <FieldFormat {...cellProps} />
      //   },
      // },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                makePaymentFn(cellProps)
              }}
            >
              Pay
            </Button>
          )
        },
      },
    ],
    []
  )

  const makePaymentFn = evt => {
    console.log("this is the beneficiary record :: ", evt.cell.row.values)
    const beneficiary = evt.cell.row.values
    Swal.fire({
      title: "Processing Payment...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    const paymentDto = {
      beneficiaryId: beneficiary.id,
      month: beneficiary.month,
      year: beneficiary.year,
    }
    makePayment.mutate(paymentDto)
  }

  const makePayment = useMutation({
    mutationFn: payload => {
      return GENERAL.paySingleBeneficiary(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("paySingleBeneficiary response ::", res)
      if (res.status === 201) {
        return Swal.fire("Process Successful", res.data.message, "success")
      }
      if (res.status === 400) {
        return Swal.fire("Process Failed!", res.data.message, "error")
      }
    },
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const FormatBold = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FieldFormat = cell => {
    return cell.value ? cell.value : ""
  }

  const payAllFn = evt => {
    console.log("this is the beneficiary record :: ", data[0])
    const beneficiary = data[0]
    Swal.fire({
      title: "Processing Payment...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    const paymentDto = {
      lga: beneficiary.lga_of_origin,
      month: beneficiary.month,
      year: beneficiary.year,
    }
    console.log("paymentDto :: ", paymentDto)
    payAll.mutate(paymentDto)
  }

  const payAll = useMutation({
    mutationFn: payload => {
      return GENERAL.payAllLga(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("payAllLga response ::", res)
      if (res.status === 201) {
        Swal.fire("Process Successful", res.data.message, "success")
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              parentLink={props.t("Payment")}
              childLink={props.t("Beneficiary")}
              thirdLink={props.t("LGA")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">
                All Beneficiaries - {data.length}
              </div>
              <Fragment>
                <Row className="mb-2">
                  {/* {isGlobalFilter && <GlobalFilter />} */}
                  <React.Fragment>
                    <Row>
                      <Col md={6}>
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div
                            className="position-relative"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              htmlFor="search-bar-0"
                              className="search-label"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                onChange={e => {
                                  setValue(e.target.value)
                                  onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`${count} records...`}
                                value={value || ""}
                              />
                            </label>
                            <i
                              className="bx bx-search-alt search-icon"
                              style={{ cursor: "pointer" }}
                              onClick={handleSearchFn}
                            ></i>
                            <div
                              className="d-inline-block"
                              style={{ marginLeft: 5, marginBottom: 8 }}
                            >
                              <Button
                                type="button"
                                color="primary"
                                className="btn-md btn-rounded"
                                onClick={handleSearchFn}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            className="btn mb-2 me-2"
                            onClick={payAllFn}
                          >
                            Pay All
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={gotoPreviousPage}
                        disabled={page == 1}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page <strong>{page}</strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button color="primary" onClick={gotoNextPage}>
                        {">"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewBeneficiaryPaymentsByLga)
