import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"
import { useParams } from "react-router"
import ActivityLoader from "components/activity-loader"

const BeneficiaryPrincipalProfile = () => {
  const params = useParams()

  const { data: profileInfo, isLoading } = useQuery({
    queryKey: ["getBeneficiaryByBeneficiaryId", params.id],
    queryFn: () => {
      return GENERAL.getBeneficiaryByBeneficiaryId(params.id)
    },
  })

  const { data: getBeneficiaryById, isLoading: photoLoading } = useQuery({
    queryKey: ["getBeneficiaryById", params?.id],
    queryFn: () => {
      return GENERAL.getBeneficiaryById(params?.id)
    },
  })

  return (
    <React.Fragment>
      <div className="bg-white box-shadow-css">
        <div className="text-white ">
          <p className=" bg-primary py-1 px-1 col-2 m-0 w-2">
            Beneficiary's Profile
          </p>
        </div>
        {isLoading ? (
          <center className="py-5">
            <ActivityLoader variant="primary" />
          </center>
        ) : (
          <Card>
            <Row>
              <Col lg="12">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col lg="2" className=" mt-5">
                          <div className="d-flex text-center">
                            {photoLoading ? (
                              ""
                            ) : (
                              <div className="ms-3 text-center">
                                <img
                                  src={`data:image/jpeg;base64,${getBeneficiaryById?.data?.photo}`}
                                  width={400}
                                  height={400}
                                  style={{ borderRadius: "50%" }}
                                  alt=""
                                  className="avatar-xl"
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="10">
                          <div className="row row-cols-lg-5">
                            <Col className="mt-5">
                              <p className="mb-1">Application ID</p>
                              <p>{profileInfo?.beneficiaryId ?? "---"}</p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Number of Dependants</p>
                              <p>
                                {profileInfo?.dependentBeneficiaries.length ??
                                  "---"}
                              </p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Surname</p>
                              <p>
                                {profileInfo?.surname !== ""
                                  ? profileInfo.surname
                                  : "---"}
                              </p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">First Name</p>
                              <p>
                                {profileInfo?.firstName !== ""
                                  ? profileInfo.firstName
                                  : "---"}
                              </p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Middle Name</p>
                              <p>
                                {profileInfo?.middleName !== ""
                                  ? profileInfo.middleName
                                  : "---"}
                              </p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Gender</p>
                              <p>{profileInfo?.gender ?? "---"}</p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">State Of Origin</p>
                              <p>{profileInfo?.stateOfOrigin ?? "---"}</p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Phone Number</p>
                              <p>
                                {profileInfo?.phoneNumber !== ""
                                  ? profileInfo.phoneNumber
                                  : "---"}
                              </p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Home Address</p>
                              <p>{profileInfo?.address ?? "---"}</p>
                            </Col>

                            <Col className="mt-5">
                              <p className="mb-1">Beneficiary Category</p>
                              <p>{profileInfo?.category ?? "---"}</p>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Col>
            </Row>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

export default BeneficiaryPrincipalProfile
