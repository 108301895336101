import React, { lazy } from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
// import Login from "../pages/Authentication/Login2"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ViewVerifications from "pages/Verification/ViewVerifications"
import BeneficiaryPayments from "pages/Payments/BeneficiaryPayments"
import BeneficiaryPaymentsByLga from "pages/Payments/BeneficiaryPaymentsByLga"
import ViewBeneficiaryPaymentsByLga from "pages/Payments/ViewBeneficiaryPaymentsByLga"
import ViewBeneficiaryPaymentsByState from "pages/Payments/ViewBeneficiaryPaymentsByState"
import Beneficiary from "../pages/Beneficiary/Beneficiary"
import BeneficiaryByLga from "../pages/Beneficiary/BeneficiaryByLga"
import ViewBeneficiaryByState from "../pages/Beneficiary/ViewBeneficiaryByState"
import ViewBeneficiaryByLga from "../pages/Beneficiary/ViewBeneficiaryByLga"
import Agents from "../pages/Agents/Agents"
import AgentPayments from "../pages/Payments/AgentPayments"
import AgentPaymentsByLga from "../pages/Payments/AgentPaymentsByLga"
import ViewAgentPaymentsByState from "../pages/Payments/ViewAgentPaymentsByState"
import ViewAgentPaymentsByLga from "../pages/Payments/ViewAgentPaymentsByLga"

import UserList from "pages/ManageUsers/UserList"
import AgentProfile from "pages/ManageUsers/UserProfile"
import EligibilitySetup from "../pages/Config/EligibilitySetup"
import ProgramSetup from "../pages/Config/ProgramSetup"
import ForgotPassword from "pages/Authentication/ForgotPassword"
import ReportBuilder from "pages/ReportBuilder"

import UploadedDocuments from "pages/UploadedDocuments"
import LgaCoordinatorBeneficiaries from "pages/Beneficiary/Beneficiary/LgaCoordinatorBeneficiaries"
import BeneficiaryProfile from "pages/Beneficiary/BeneficiaryProfile"
import DependantsProfile from "pages/Beneficiary/BeneficiaryProfile/dependantsProfile"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // payments routes
  {
    path: "/agent-payments",
    component: <AgentPayments />,
  },
  {
    path: "/agent-payments-by-lga/:id/:state",
    component: <AgentPaymentsByLga />,
  },
  {
    path: "/view-agent-payments-by-state/:id",
    component: <ViewAgentPaymentsByState />,
  },
  {
    path: "/view-agent-payments-by-lga/:id",
    component: <ViewAgentPaymentsByLga />,
  },
  {
    path: "/beneficiary-payment",
    component: <BeneficiaryPayments />,
  },
  {
    path: "/beneficiary-payment-by-lga/:id/:state",
    component: <BeneficiaryPaymentsByLga />,
  },
  {
    path: "/view-beneficiary-payment-by-state/:id/state/:state",
    component: <ViewBeneficiaryPaymentsByState />,
  },
  {
    path: "/view-beneficiary-payment-by-lga/:id/lga/:lga",
    component: <ViewBeneficiaryPaymentsByLga />,
  },

  // beneficiary routes
  {
    path: "/beneficiary",
    component: <Beneficiary />,
  },
  {
    path: "/beneficiary-by-lga/:id/:state",
    component: <BeneficiaryByLga />,
  },
  {
    path: "/view-beneficiary-by-state/:id",
    component: <ViewBeneficiaryByState />,
  },
  {
    path: "/beneficiary-by-lga-state-coordinator",
    component: <BeneficiaryByLga />,
  },

  {
    path: "/view-beneficiary-by-lga/lga-coordinator",
    component: <LgaCoordinatorBeneficiaries />,
  },

  {
    path: "/view-beneficiary-by-lga/:id?/lga/:lga",
    component: <ViewBeneficiaryByLga />,
  },

  // verification routes
  { path: "/verifications", component: <ViewVerifications /> },

  // paymemt routes
  { path: "/agents", component: <Agents /> },
  // paymemt routes
  { path: "/eligibility-setup-old", component: <EligibilitySetup /> },
  { path: "/eligibility-setup", component: <ProgramSetup /> },
  // { path: "/program-setup", component: <ProgramSetup /> },

  // report builder
  { path: "/report-builder", component: <ReportBuilder /> },

  // //profile
  { path: "/profile/:id", component: <UserProfile /> },
  { path: "/beneficiary-profile/:id", component: <BeneficiaryProfile /> },
  {
    path: "/beneficiary-profile/dependants/:dependantId",
    component: <DependantsProfile />,
  },
  { path: "/manage-users", component: <UserList /> },
  { path: "/agents/:id", component: <AgentProfile /> },

  // uploaded documents
  { path: "/uploaded-documents", component: <UploadedDocuments /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
